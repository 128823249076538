import React from 'react';
import { NavLink } from 'react-router-dom';

import starTrekCcgLogoPng from '../static/star-trek-ccg-logo.png';

const Header = () => (
  <>
    <div className="header">
      <img src={starTrekCcgLogoPng} className="center_img header_logo" alt="Star Trek CCG Logo" />
    </div>
    <div className="sub_header">
      <ul>
        <li>
          <NavLink to="/" exact activeClassName="active">Games</NavLink>
        </li>
        <li>
          <NavLink to="/standings" exact activeClassName="active">Standings</NavLink>
        </li>
        <li>
          <NavLink to="/tournaments" exact activeClassName="active">Tournaments</NavLink>
        </li>
      </ul>
    </div>
  </>
);

export default Header;
