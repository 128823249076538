import React from 'react';

import starTrekCcgIconPng from '../static/star-trek-ccg-icon.png';

const RightMenu = () => (
  <div className="right_menu">
    <img src={starTrekCcgIconPng} alt="Star Trek CCG Icon" className="rm_top_icon" />
    <ul>
      <li><a href="#" className="active"><span className="str-home-house-streamline"></span><span className="rm_item">Home</span></a></li>
      <li><a href="#"><span className="str-book-read-streamline"></span><span className="rm_item">Resources</span></a></li>
      <li><a href="#"><span className="str-photo-pictures-streamline deck_builder_icon"></span><span className="rm_item">Deck Builder</span></a></li>
      <li><a href="#"><span className="str-crown-king-streamline"></span><span className="rm_item">Hall of Fame</span></a></li>
    </ul>
  </div>
);

export default RightMenu;
