import React from 'react';

const Standings = () => (
  <div className="content standings">
    <div className="inner_container_980">
      <div className="standings_header">
        <div className="std_hdr_player_col"><p>Player</p></div>
        <div className="std_hdr_wl_col"><p>W</p><p>L</p><p>GP</p><p>PCT</p></div>
        <div className="std_hdr_pts_col"><p>PF</p><p>PA</p><p>NET</p></div>
        <div className="std_hdr_stk_col"><p>STK</p></div>
      </div>

      <div className="std_player" id="graham_matuszewski">
        <div className="std_player_avatar"></div>
        <div className="std_player_name"><p>G. Matuszewski</p></div>
        <div className="std_player_wl">
          {/*<!--W-->*/}<p><b>6</b></p>{/*<!--L-->*/}<p><b>0</b></p>{/*<!--GP-->*/}<p>6</p>{/*<!--PCT-->*/}<p>1.000</p>
        </div>
        <div className="std_player_pts">
          {/*<!--PF-->*/}<p>663</p>{/*<!--PA-->*/}<p>95</p>{/*<!--NET-->*/}<p>568</p>
        </div>
        <div className="std_player_stk">
          {/*<!--STK-->*/}<p>W6</p>
        </div>
      </div>

      <div className="std_player" id="robert_bozich">
        <div className="std_player_avatar"></div>
        <div className="std_player_name"><p>R. Bozich</p></div>
        <div className="std_player_wl">
          {/*<!--W-->*/}<p><b>6</b></p>{/*<!--L-->*/}<p><b>1</b></p>{/*<!--GP-->*/}<p>7</p>{/*<!--PCT-->*/}<p>.857</p>
        </div>
        <div className="std_player_pts">
          {/*<!--PF-->*/}<p>525</p>{/*<!--PA-->*/}<p>187</p>{/*<!--NET-->*/}<p>338</p>
        </div>
        <div className="std_player_stk">
          {/*<!--STK-->*/}<p>W3</p>
        </div>
      </div>

      <div className="std_player" id="matt_lubner">
        <div className="std_player_avatar"></div>
        <div className="std_player_name"><p>M. Lubner</p></div>
        <div className="std_player_wl">
          {/*<!--W-->*/}<p><b>3</b></p>{/*<!--L-->*/}<p><b>3</b></p>{/*<!--GP-->*/}<p>6</p>{/*<!--PCT-->*/}<p>.500</p>
        </div>
        <div className="std_player_pts">
          {/*<!--PF-->*/}<p>316</p>{/*<!--PA-->*/}<p>501</p>{/*<!--NET-->*/}<p>-185</p>
        </div>
        <div className="std_player_stk">
          {/*<!--STK-->*/}<p>W2</p>
        </div>
      </div>

      <div className="std_player" id="chris_ledet">
        <div className="std_player_avatar"></div>
        <div className="std_player_name"><p>C. Ledet</p></div>
        <div className="std_player_wl">
          {/*<!--W-->*/}<p><b>2</b></p>{/*<!--L-->*/}<p><b>3</b></p>{/*<!--GP-->*/}<p>5</p>{/*<!--PCT-->*/}<p>.400</p>
        </div>
        <div className="std_player_pts">
          {/*<!--PF-->*/}<p>319</p>{/*<!--PA-->*/}<p>282</p>{/*<!--NET-->*/}<p>37</p>
        </div>
        <div className="std_player_stk">
          {/*<!--STK-->*/}<p>W2</p>
        </div>
      </div>


      <div className="std_player" id="rex_feany">
        <div className="std_player_avatar"></div>
        <div className="std_player_name"><p>R. Feany</p></div>
        <div className="std_player_wl">
          {/*<!--W-->*/}<p><b>1</b></p>{/*<!--L-->*/}<p><b>4</b></p>{/*<!--GP-->*/}<p>4</p>{/*<!--PCT-->*/}<p>.200</p>
        </div>
        <div className="std_player_pts">
          {/*<!--PF-->*/}<p>158</p>{/*<!--PA-->*/}<p>400</p>{/*<!--NET-->*/}<p>-242</p>
        </div>
        <div className="std_player_stk">
          {/*<!--STK-->*/}<p>L2</p>
        </div>
      </div>


      <div className="std_player" id="jon_callan">
        <div className="std_player_avatar"></div>
        <div className="std_player_name"><p>J. Callan</p></div>
        <div className="std_player_wl">
          {/*<!--W-->*/}<p><b>1</b></p>{/*<!--L-->*/}<p><b>6</b></p>{/*<!--GP-->*/}<p>7</p>{/*<!--PCT-->*/}<p>.143</p>
        </div>
        <div className="std_player_pts">
          {/*<!--PF-->*/}<p>220</p>{/*<!--PA-->*/}<p>534</p>{/*<!--NET-->*/}<p>-314</p>
        </div>
        <div className="std_player_stk">
          {/*<!--STK-->*/}<p>L6</p>
        </div>
      </div>

      <div className="std_player" id="rob_green">
        <div className="std_player_avatar"></div>
        <div className="std_player_name"><p>R. Green</p></div>
        <div className="std_player_wl">
          {/*<!--W-->*/}<p><b>0</b></p>{/*<!--L-->*/}<p><b>2</b></p>{/*<!--GP-->*/}<p>2</p>{/*<!--PCT-->*/}<p>.000</p>
        </div>
        <div className="std_player_pts">
          {/*<!--PF-->*/}<p>10</p>{/*<!--PA-->*/}<p>212</p>{/*<!--NET-->*/}<p>-202</p>
        </div>
        <div className="std_player_stk">
          {/*<!--STK-->*/}<p>L2</p>
        </div>
      </div>
    </div>
  </div>
);

export default Standings;
