import React from 'react';

import Match from '../components/Match/Match';
import MatchBlock from '../components/MatchBlock/MatchBlock';

const Games = () => (
  <div className="content">
    <div className="inner_container_980">
      <MatchBlock date="Thursday, September 5th, 2019">
        <Match
          playerOne={{
            affiliation: "federation",
            name: "J. Callan",
            score: 0,
          }}
          playerTwo={{
            affiliation: "dominion",
            name: "R. Bozich",
            score: 100,
          }}
          winner="playerTwo"
        />
        <Match
          playerOne={{
            affiliation: "non_aligned",
            name: "M. Lubner",
            score: 100,
          }}
          playerTwo={{
            affiliation: "federation",
            name: "R. Feany",
            score: 35,
          }}
          winner="playerOne"
        />
      </MatchBlock>
      <MatchBlock date="Thursday, August 22nd, 2019">
        <Match
          playerOne={{
            affiliation: "non_aligned",
            name: "M. Lubner",
            score: 115,
          }}
          playerTwo={{
            affiliation: "klingon",
            name: "J. Callan",
            score: 95,
          }}
          winner="playerOne"
        />
        <Match
          playerOne={{
            affiliation: "federation",
            name: "R. Feany",
            score: 0,
          }}
          playerTwo={{
            affiliation: "dominion",
            name: "R. Bozich",
            score: 120,
          }}
          winner="playerTwo"
        />
      </MatchBlock>
      <MatchBlock date="Tuesday, August 13th, 2019">
        <Match
          playerOne={{
            affiliation: "klingon",
            name: "J. Callan",
            score: 0,
          }}
          playerTwo={{
            affiliation: "ferengi",
            name: "G. Matuszewski",
            score: 100,
          }}
          winner="playerTwo"
        />
        <Match
          playerOne={{
            affiliation: "non_aligned",
            name: "M. Lubner",
            score: 45,
          }}
          playerTwo={{
            affiliation: "dominion",
            name: "R. Bozich",
            score: 115,
          }}
          winner="playerTwo"
        />
      </MatchBlock>
      <MatchBlock date="Monday, July 29th, 2019">
        <Match
          playerOne={{
            affiliation: "ferengi",
            name: "G. Matuszewski",
            score: 102,
          }}
          playerTwo={{
            affiliation: "non_aligned",
            name: "R. Bozich",
            score: -20,
          }}
          winner="playerOne"
        />
        <Match
          playerOne={{
            affiliation: "federation",
            name: "C. Ledet",
            score: 102,
          }}
          playerTwo={{
            affiliation: "federation",
            name: "R. Green",
            score: -10,
          }}
          winner="playerOne"
        />
        <Match
          playerOne={{
            affiliation: "klingon",
            name: "J. Callan",
            score: 45,
          }}
          playerTwo={{
            affiliation: "federation",
            name: "M. Lubner",
            score: 76,
          }}
          winner="playerTwo"
        />
      </MatchBlock>
      <MatchBlock date="Wednesday, July 24th, 2019">
        <Match
          playerOne={{
            affiliation: 'federation',
            name: 'R. Feany',
            score: 110,
          }}
          playerTwo={{
            affiliation: 'federation',
            name: 'R. Green',
            score: 20,
          }}
          winner="playerOne"
        />
        <Match
          playerOne={{
            affiliation: "federation",
            name: "M. Lubner",
            score: 0,
          }}
          playerTwo={{
            affiliation: "federation",
            name: "C. Ledet",
            score: 107,
          }}
          winner="playerTwo"
        />
        <Match
          playerOne={{
            affiliation: "ferengi",
            name: "G. Matuszewski",
            score: 125,
          }}
          playerTwo={{
            affiliation: "klingon",
            name: "J. Callan",
            score: 35,
          }}
          winner="playerOne"
        />
      </MatchBlock>
      <MatchBlock date="Monday, July 15th, 2019">
        <Match
          playerOne={{
            affiliation: 'federation',
            name: 'C. Ledet',
            score: 30,
          }}
          playerTwo={{
            affiliation: 'ferengi',
            name: 'G. Matuszewski',
            score: 129,
          }}
          winner="playerTwo"
        />
        <Match
          playerOne={{
            affiliation: 'non_aligned',
            name: 'R. Bozich',
            score: 35,
          }}
          playerTwo={{
            affiliation: 'federation',
            name: 'J. Callan',
            score: 0,
          }}
          winner="playerOne"
        />
      </MatchBlock>
      <MatchBlock date="Thursday, July 11th, 2019">
        <Match
          playerOne={{
            affiliation: 'federation',
            name: 'R. Feany',
            score: -17,
          }}
          playerTwo={{
            affiliation: 'klingon',
            name: 'J. Callan',
            score: 45,
          }}
          winner="playerTwo"
        />
        <Match
          playerOne={{
            affiliation: 'non_aligned',
            name: 'R. Bozich',
            score: 60,
          }}
          playerTwo={{
            affiliation: 'federation',
            name: 'C. Ledet',
            score: 10,
          }}
          winner="playerOne"
        />
        <Match
          playerOne={{
            affiliation: 'ferengi',
            name: 'G. Matuszewski',
            score: 104,
          }}
          playerTwo={{
            affiliation: 'federation',
            name: 'M. Lubner',
            score: -20,
          }}
          winner="playerOne"
        />
      </MatchBlock>
      <MatchBlock date="Tuesday, July 2nd, 2019">
        <Match
          playerOne={{
            affiliation: 'ferengi',
            name: 'G. Matuszewski',
            score: 103,
          }}
          playerTwo={{
            affiliation: 'federation',
            name: 'C. Ledet',
            score: 70,
          }}
          winner="playerOne"
        />
        <Match
          playerOne={{
            affiliation: 'dominion',
            name: 'R. Bozich',
            score: 115,
          }}
          playerTwo={{
            affiliation: 'federation',
            name: 'R. Feany',
            score: 30,
          }}
          winner="playerOne"
        />
      </MatchBlock>
    </div>
  </div>
);

export default Games;
