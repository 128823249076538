export const affiliations = {
  default: {
    image: require('./static/flag-default.png'),
    color: '#8e98aa',
  },
  federation: {
    image: require('./static/flag-federation.png'),
    color: '#2bbcee',
  },
  klingon: {
    image: require('./static/flag-klingon.png'),
    color: '#000000',
  },
  romulan: {
    image: require('./static/flag-romulan.png'),
    color: '#82bc82',
  },
  non_aligned: {
    image: require('./static/flag-non-aligned.png'),
    color: '#c9ab2d',
  },
  borg: {
    image: require('./static/flag-borg.png'),
    color: '#24292b',
  },
  cardassian: {
    image: require('./static/flag-cardassian.png'),
    color: '#6268af',
  },
  bajoran: {
    image: require('./static/flag-bajoran.png'),
    color: '#964585',
  },
  dominion: {
    image: require('./static/flag-dominion.png'),
    color: '#84620b',
  },
  ferengi: {
    image: require('./static/flag-ferengi.png'),
    color: '#ed7a31',
  },
};
