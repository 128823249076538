import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { maxTabletPortrait } from '../../mediaQueries';

const MatchBlockWrapper = styled.div`
  padding-bottom: 25px;
`;

const HeadingDate = styled.h4`
  color: #333d50;
  text-align: center;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  margin: 13px 0 18px;

  @media ${maxTabletPortrait} {
    font-size: 11px;
    margin-bottom: 8px;
  }
`;

const MatchBlock = ({ children, date, ...props }) => (
  <MatchBlockWrapper {...props}>
    <HeadingDate>{date}</HeadingDate>
    {children}
  </MatchBlockWrapper>
);

MatchBlock.propTypes = {
  children: PropTypes.any.isRequired,
  date: PropTypes.string.isRequired,
};

export default MatchBlock;
