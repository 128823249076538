import React from 'react';

import starTrekCcgFooterIconPng from '../static/star-trek-ccg-footer-icon.png';

const Footer = () => (
  <div className="footer">
    <div className="inner_container_980">
      <img src={starTrekCcgFooterIconPng} className="footer_icon" alt="Star Trek CCG Icon" />
      <p>©2019-2020. STAR TREK™ CUSTOMIZABLE CARD GAME. STAR TREK™ RELOADED.</p>
    </div>
  </div>
);

export default Footer;
