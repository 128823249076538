import React from 'react';

import starTrekCcgDraftTournamentLogoPng from '../static/star-trek-ccg-draft-tournament-logo.png';

const Tournaments = () => (
  <div className="content">
    <div className="inner_container_980">
      <div className="match_block" id="20190918-1">
        <div style={{
          textAlign: 'center',
          padding: '28px 0 20px 0',
          backgroundColor: '#B22122',
          marginBottom: '30px',
        }}>
          <img src={starTrekCcgDraftTournamentLogoPng} style={{ width: '400px' }} alt="Star Trek CCG Draft Tournament" />
        </div>
        <div className="match_date"><p>Wednesday, September 18th &nbsp; / &nbsp; Group Stage</p></div>
        <div className="match_round"><p>ROUND 1</p></div>
        <div className="match" id="20190918-1-1">
          <div className="match_away">
            <div className="affiliation klingon"></div>
            <div className="player_away loss"><p>R. Feany (0-1)</p></div>
            <div className="score_away loss"><p>0</p></div>
          </div>
          <div className="match_home">
            <div className="affiliation federation"></div>
            <div className="player_home"><p>(1-0) R. Bozich</p></div>
            <div className="score_home"><p>75</p></div>
          </div>
        </div>
        <div className="match" id="20190918-1-2">
          <div className="match_away">
            <div className="affiliation romulan"></div>
            <div className="player_away loss"><p>M. Lubner (0-1)</p></div>
            <div className="score_away loss"><p>25</p></div>
          </div>
          <div className="match_home">
            <div className="affiliation federation"></div>
            <div className="player_home"><p>(1-0) J. Callan</p></div>
            <div className="score_home"><p>80</p></div>
          </div>
        </div>
      </div>
      <div className="match_block" id="20190918-2">
        <div className="match_round"><p>ROUND 2</p></div>
        <div className="match" id="20190918-2-1">
          <div className="match_away">
            <div className="affiliation romulan"></div>
            <div className="player_away loss"><p>M. Lubner (0-2)</p></div>
            <div className="score_away loss"><p>0</p></div>
          </div>
          <div className="match_home">
            <div className="affiliation federation"></div>
            <div className="player_home"><p>(2-0) R. Bozich</p></div>
            <div className="score_home"><p>70</p></div>
          </div>
        </div>
        <div className="match" id="20190918-2-2">
          <div className="match_away">
            <div className="affiliation romulan"></div>
            <div className="player_away"><p>G. Matuszewski (1-0)</p></div>
            <div className="score_away"><p>80</p></div>
          </div>
          <div className="match_home">
            <div className="affiliation federation"></div>
            <div className="player_home loss"><p>(1-1) J. Callan</p></div>
            <div className="score_home loss"><p>50</p></div>
          </div>
        </div>
      </div>
      <div className="match_block" id="20190918-3">
        <div className="match_round"><p>ROUND 3</p></div>
        <div className="match" id="20190918-1">
          <div className="match_away">
            <div className="affiliation romulan"></div>
            <div className="player_away"><p>G. Matuszewski (2-0)</p></div>
            <div className="score_away"><p>75</p></div>
          </div>
          <div className="match_home">
            <div className="affiliation federation"></div>
            <div className="player_home loss"><p>(2-1) R. Bozich</p></div>
            <div className="score_home loss"><p>30</p></div>
          </div>
        </div>
        <div className="match" id="20190918-2">
          <div className="match_away">
            <div className="affiliation klingon"></div>
            <div className="player_away"><p>R. Feany (1-1)</p></div>
            <div className="score_away"><p>75</p></div>
          </div>
          <div className="match_home">
            <div className="affiliation romulan"></div>
            <div className="player_home loss"><p>(0-3) M. Lubner</p></div>
            <div className="score_home loss"><p>50</p></div>
          </div>
        </div>
      </div>
      <div className="match_block" id="20190918-4">
        <div className="match_round"><p>ROUND 4</p></div>
        <div className="match" id="20190918-4-1">
          <div className="match_away">
            <div className="affiliation federation"></div>
            <div className="player_away"><p>J. Callan (2-1)</p></div>
            <div className="score_away"><p>80</p></div>
          </div>
          <div className="match_home">
            <div className="affiliation federation"></div>
            <div className="player_home loss"><p>(2-2) R. Bozich</p></div>
            <div className="score_home loss"><p>60</p></div>
          </div>
        </div>
        <div className="match" id="20190918-4-2">
          <div className="match_away">
            <div className="affiliation romulan"></div>
            <div className="player_away"><p>G. Matuszewski (3-0)</p></div>
            <div className="score_away"><p>75</p></div>
          </div>
          <div className="match_home">
            <div className="affiliation romulan"></div>
            <div className="player_home loss"><p>(0-4) M. Lubner</p></div>
            <div className="score_home loss"><p>5</p></div>
          </div>
        </div>
      </div>
      <div className="match_block" id="20190918-5">
        <div className="match_round"><p>ROUND 5</p></div>
        <div className="match" id="20190918-5-1">
          <div className="match_away">
            <div className="affiliation federation"></div>
            <div className="player_away"><p>J. Callan (3-1)</p></div>
            <div className="score_away"><p>85</p></div>
          </div>
          <div className="match_home">
            <div className="affiliation klingon"></div>
            <div className="player_home loss"><p>(1-2) R. Feany</p></div>
            <div className="score_home loss"><p>0</p></div>
          </div>
        </div>
      </div>
      <div className="match_block" id="20190918-6">
        <div className="match_round"><p>ROUND 6</p></div>
        <div className="match" id="20190918-6-1">
          <div className="match_away">
            <div className="affiliation romulan"></div>
            <div className="player_away"><p>G. Matuszewski (4-0)</p></div>
            <div className="score_away"><p>70</p></div>
          </div>
          <div className="match_home">
            <div className="affiliation klingon"></div>
            <div className="player_home loss"><p>(1-3) R. Feany</p></div>
            <div className="score_home loss"><p>40</p></div>
          </div>
        </div>
      </div>
      <div className="match_block" id="20190923-1">
        <div className="match_date"><p>Monday, September 23rd &nbsp; / &nbsp; Knock-out Stage</p></div>
        <div className="match_round"><p>Wild Card</p></div>
        <div className="match" id="20190702-1-1">
          <div className="match_away">
            <div className="affiliation klingon"></div>
            <div className="player_away"><p>R. Feany (4)</p></div>
            <div className="score_away"><p>0</p></div>
          </div>
          <div className="match_home">
            <div className="affiliation romulan"></div>
            <div className="player_home"><p>(5) M. Lubner</p></div>
            <div className="score_home"><p>0</p></div>
          </div>
        </div>
      </div>
      <div className="match_block" id="20190923-2">
        <div className="match_round"><p>Semifinals</p></div>
        <div className="match" id="20190702-2-1">
          <div className="match_away">
            <div className="affiliation federation"></div>
            <div className="player_away"><p>J. Callan (2)</p></div>
            <div className="score_away"><p>0</p></div>
          </div>
          <div className="match_home">
            <div className="affiliation federation"></div>
            <div className="player_home"><p>(3) R. Bozich</p></div>
            <div className="score_home"><p>0</p></div>
          </div>
        </div>
        <div className="match" id="20190702-2-2">
          <div className="match_away">
            <div className="affiliation romulan"></div>
            <div className="player_away"><p>G. Matuszewski (1)</p></div>
            <div className="score_away"><p>0</p></div>
          </div>
          <div className="match_home">
            <div className="affiliation default"></div>
            <div className="player_home"><p>(4)/(5) TBD</p></div>
            <div className="score_home"><p>0</p></div>
          </div>
        </div>
      </div>
      <div className="match_block" id="20190923-3">
        <div className="match_round"><p>Final</p></div>
        <div className="match" id="20190923-3-1">
          <div className="match_away">
            <div className="affiliation default"></div>
            <div className="player_away"><p>TBD (2)/(3)</p></div>
            <div className="score_away"><p>0</p></div>
          </div>
          <div className="match_home">
            <div className="affiliation default"></div>
            <div className="player_home"><p>(1)/(4)/(5) TBD</p></div>
            <div className="score_home"><p>0</p></div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Tournaments;
