import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { affiliations } from '../../affiliations';
import { maxTabletPortrait } from '../../mediaQueries';

const MatchWrapper = styled.div`
  display: flex;
  height: 70px;
  margin-top: 8px;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;

  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
  transition: box-shadow 200ms ease-out;
  :hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  }

  @media ${maxTabletPortrait} {
    height: 50px;
  }
`;

const PlayerResult = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 50%;
  color: ${props => props.isWinner ? '#333d50' : '#333d5064'};

  & + & {
    flex-direction: row-reverse;
  }
`;

const PlayerAffiliation = styled.div`
  align-self: stretch;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => affiliations[props.affiliation]?.image || affiliations.default.image});
  background-color: ${props => affiliations[props.affiliation]?.color || affiliations.default.color};

  width: 95px;
  @media ${maxTabletPortrait} {
    width: 65px;
  }
`;

const PlayerName = styled.div`
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 18px 8px;

  @media ${maxTabletPortrait} {
    font-size: 25px;
    margin-bottom: 2px;
  }
`;

const PlayerScore = styled(PlayerName)`
  display: flex;
  flex-direction: inherit;
  justify-content: flex-end;
  flex: 1 0 auto;
`;

const Match = ({ winner, playerOne, playerTwo, ...props }) => (
  <MatchWrapper {...props}>
    <PlayerResult isWinner={winner === 'playerOne'}>
      <PlayerAffiliation affiliation={playerOne.affiliation} />
      <PlayerName>{playerOne.name}</PlayerName>
      <PlayerScore>{playerOne.score}</PlayerScore>
    </PlayerResult>
    <PlayerResult isWinner={winner === 'playerTwo'}>
      <PlayerAffiliation affiliation={playerTwo.affiliation} />
      <PlayerName>{playerTwo.name}</PlayerName>
      <PlayerScore>{playerTwo.score}</PlayerScore>
    </PlayerResult>
  </MatchWrapper>
);

Match.propTypes = {
  playerOne: PropTypes.shape({
    affiliation: PropTypes.string.isRequired,
    isWinner: PropTypes.bool,
    name: PropTypes.string.isRequired,
    score: PropTypes.number.isRequired,
  }).isRequired,
  playerTwo: PropTypes.shape({
    affiliation: PropTypes.string.isRequired,
    isWinner: PropTypes.bool,
    name: PropTypes.string.isRequired,
    score: PropTypes.number.isRequired,
  }).isRequired,
  winner: PropTypes.oneOf(['playerOne', 'playerTwo']).isRequired,
};

export default Match;
