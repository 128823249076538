import React from 'react';
import { Switch, Route } from 'react-router-dom';

import './App.css';
import RightMenu from './components/RightMenu';
import Header from './components/Header';
import Footer from './components/Footer';

import Games from './pages/Games';
import Standings from './pages/Standings';
import Tournaments from './pages/Tournaments';

const App = () => (
  <div className="container">
    <RightMenu />
    <div className="page">
      <Header />
      <Switch>
        <Route path="/" exact component={Games} />
        <Route path="/standings" exact component={Standings} />
        <Route path="/tournaments" exact component={Tournaments} />
      </Switch>
      <Footer />
    </div>
  </div>
);

export default App;
